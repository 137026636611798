
import React from 'react'
import '../styles/cart.css'
import Helmet from '../components/Helmet/Helmet'
import CommonSelection from '../components/UI/commonSelection'
import { Container,Row,Col } from 'reactstrap'

import {motion} from 'framer-motion'
import {cartActions} from '../redux/slices/cartSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const Cart = () => {

  const cartItems = useSelector(state=> state.cart.cartItems) 
  const totalAmount = useSelector(state=>state.cart.totalAmount)

  return (
    <Helmet title='Cart'>
      <CommonSelection title='Shopping Cart'></CommonSelection>

      <section>
        <Container>
          <Row>
            <Col lg='9'>
              {cartItems.length ===0? <h2 className='fs-4 text-center'>No Products added to Cart</h2>:(<table className='table bordered'>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cartItems.map((item,index)=>(
                     <Tr item={item} key={index}></Tr>
                    ))
                  }
                </tbody>
              </table>)}
            </Col>
            <Col lg='3'>
              <div>
                <h6 className='d-flex align-items-center justify-content-between'>
                  Subtotal
                  <span className='fs-4 fw-bold'>${totalAmount}</span>
                  </h6>
               

              </div>
              <p className='fs-6 mt-2'>Taxes and Shipping costs will be calculated in checkout</p>

              <div>
              <button className="buy_btn w-100 " > <Link to={'/checkout'}>Checkout Now</Link></button>
                <button className="buy_btn w-100 mt-3" > <Link to={'/shop'}>Continue Shopping</Link></button>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

const Tr = ({item}) =>{

  const dispatch = useDispatch()
  const deleteProduct = () =>{
    dispatch(cartActions.deleteItem(item.id))
  }
  return (
  <tr >
  <td><img src={item.imgUrl} alt=''></img></td>
  <td>{item.productName}</td>
  <td>${item.price}</td>
  <td>{item.quantity}px</td>
  <td><motion.i onClick={deleteProduct} whileTap={{scale:1.2}} class="ri-star-s-fill"></motion.i></td>
</tr>
  )

}

export default Cart